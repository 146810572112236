import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';

import { errorToast, successToast } from 'app/utils/toast';

import Show from 'app/hoc/Show';

import Tooltip from '@material-ui/core/Tooltip';

import classes from './TruncateText.module.scss';

const handleCopy = (event, value) => {
  event.clipboardData.setData('text/plain', value);
  event.preventDefault();
};

const TOAST_OPTIONS = { autoClose: 300 };

const handleIconCopy = async value => {
  try {
    if (window.isSecureContext) {
      await navigator.clipboard.writeText(value);
    } else {
      const activeElement = document.activeElement;
      const textarea = document.createElement('textarea');
      textarea.innerText = value;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      activeElement?.focus();
    }

    successToast(`Successfully copied: ${value}`, TOAST_OPTIONS);
  } catch (err) {
    console.error(err);
    errorToast(`Failed to copy: ${value}`, TOAST_OPTIONS);
  }
};

const TruncateText = ({
  label,
  subLabel = null,
  classNames = {},
  showToolTip = true,
  tooltipText = null,
  isCopyLabel = false
}) => {
  const ref = useRef();

  const firstLabelLen = useRef(Math.floor((label.length * 70) / 100)).current;
  const lastLabelLen = useRef(label.length - firstLabelLen);

  const [posShift, setPosShift] = useState(0);

  useEffect(() => {
    if (ref.current) {
      const { scrollWidth, offsetWidth, firstElementChild, lastElementChild } =
        ref.current;
      const overflow = scrollWidth - offsetWidth;

      if (overflow) {
        const firstChildWidth = firstElementChild.offsetWidth;
        const lastChildWidth = lastElementChild.offsetWidth;

        if (lastChildWidth > offsetWidth / 2) {
          lastLabelLen.current = 5;
        }

        const parentPadding = 8;

        const gap = offsetWidth - firstChildWidth;
        const shift = scrollWidth - firstChildWidth - gap - parentPadding;

        setPosShift(-shift);
      }
    }
  }, [label, posShift]);

  return (
    <Tooltip title={showToolTip ? tooltipText || label : ''}>
      <div
        ref={ref}
        className={clsx(classNames.root, classes.truncateText, {
          [classes.copyLabel]: isCopyLabel
        })}
        onCopy={e => handleCopy(e, label)}
        onClick={() => isCopyLabel && handleIconCopy(label)}
      >
        <span className={clsx(classNames?.label1, classes.label1)}>
          {label.substring(0, firstLabelLen)}
        </span>
        <Show when={posShift !== 0}>
          <span>...</span>
        </Show>
        <span
          className={clsx(classes.label2, {
            [classes.overflow]: posShift !== 0
          })}
          style={{ left: posShift }}
        >
          {label.substring(
            Math.max(firstLabelLen, label.length - lastLabelLen.current),
            label.length
          )}

          <Show when={subLabel}>
            <span className={clsx(classNames.subLabel)}>{subLabel}</span>
          </Show>
        </span>

        <Show when={isCopyLabel}>
          <FontAwesomeIcon
            className={clsx(classNames.icon, classes.icon)}
            icon={faCopy}
          />
        </Show>
      </div>
    </Tooltip>
  );
};

export default TruncateText;
