import { WidgetActions } from '../types';
import { FLOATING_WIDGET_ACTION_CONSTS } from './constants';

export type FloatingWidgetType = {
  isWidgetOpen: boolean;
};

const getInitialState = (): FloatingWidgetType => ({
  isWidgetOpen: false
});

export const reducer = (state = getInitialState(), action: WidgetActions) => {
  switch (action.type) {
    case FLOATING_WIDGET_ACTION_CONSTS.SET_STATUS_WIDGET:
      return {
        ...state,
        isWidgetOpen: action.payload
      };

    default:
      return state;
  }
};
