import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translations from '/public/translations';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(initReactI18next)
  .use(I18nextBrowserLanguageDetector)
  .init({
    resources: translations,
    lng: window.localStorage.getItem('I18N_LANGUAGE') || 'en',
    fallbackLng: 'en',
    supportedLngs: (process.env.REACT_APP_USE_LANGUAGES || 'en').split(','),
    debug: false,
    lowerCaseLng: 'true',
    defaultNS: 'COMMON',
    fallbackNS: 'COMMON',
    load: 'currentOnly',
    interpolation: {
      escapeValue: false
    },
    detection: {
      order: ['localStorage', 'navigator'],
      lookupQuerystring: 'lng',
      lookupLocalStorage: 'I18N_LANGUAGE',
      caches: ['localStorage']
    }
  });

export default i18n;
