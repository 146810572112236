import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { getDecodedURL, isSameObject } from 'app/utils/helpers';
import { FILTER_KEYS } from 'app/utils/constants';

const { META_INFO_FILTER_KEY } = FILTER_KEYS;

type UseObserveLocationProps = string[] | null;

const useObserveLocation = (locationKeys: UseObserveLocationProps): object => {
  const location = useLocation();

  const getParamsFromLocation = () => {
    const { decodedContextual, decodedOther, ...rest } = getDecodedURL(
      location.search
    );

    return { ...decodedContextual, ...decodedOther, ...rest };
  };

  const filterLocationParamsFromAllowedKeys = () => {
    const params = getParamsFromLocation();

    if (!locationKeys) return params;

    const filteredKeys: Record<string | number | symbol, any> = {};

    for (const key of locationKeys) {
      if (key.includes(META_INFO_FILTER_KEY)) {
        for (const x in params) {
          if (x.startsWith(META_INFO_FILTER_KEY)) {
            filteredKeys[x] = params[x];
          }
        }
      } else if (params[key]) {
        filteredKeys[key] = params[key];
      }
    }

    return filteredKeys;
  };

  const [obervableLocationParams, setObservableLocationParams] = useState(
    filterLocationParamsFromAllowedKeys()
  );

  useEffect(() => {
    const updatedLocationParams = filterLocationParamsFromAllowedKeys();

    if (!isSameObject(obervableLocationParams, updatedLocationParams)) {
      setObservableLocationParams(updatedLocationParams);
    }
  }, [location.search]);

  return obervableLocationParams;
};

export default useObserveLocation;
