import { memo, useCallback, useState } from 'react';
// import { useSelector } from 'react-redux';
import clsx from 'clsx';

import useUploadDataWorker from 'app/hooks/useUploadDataWorker';

import Draggable from '../Draggable';
import { TitleCard } from './Components/TitleCard';
import { StatusCard } from './Components/StatusCard';

import classes from './FloatingStatusWidget.module.scss';
import useFolderUploadStates from 'app/hooks/FloatingWidgetHooks/FolderUpload/useFolderUploadStates';
import useFloatingWidgetStates from 'app/hooks/FloatingWidgetHooks/FloatingWidget/useFloatingWidgetStates';
import useInferenceStates from 'app/hooks/FloatingWidgetHooks/InferenceStatus/useInferenceStates';
import { InferenceCard } from './Components/InferenceCard';

export const FloatingStatusWidget = memo(() => {
  const [expanded, setExpanded] = useState(true);

  const [state] = useFolderUploadStates(true);
  const [floatingWidgetState] = useFloatingWidgetStates(true);
  const [inferenceState] = useInferenceStates(true);
  const { data: inferenceWidgetData } = inferenceState;

  const { uploadFolders, uploadIds } = state;

  // const uploadFolders = useSelector(
  //   ({ statusWidget }) => statusWidget.uploadFolders
  // );
  // const uploadIds = useSelector(({ statusWidget }) => statusWidget.uploadIds);

  const { onTerminate, onFileUploadStart, onFileRetry } = useUploadDataWorker();

  const handleClose = useCallback(() => {
    onTerminate();
  }, []);

  if (!floatingWidgetState.isWidgetOpen) return null;

  return (
    <Draggable>
      <div className={classes.floatingStatusWidget}>
        <TitleCard
          foldersLength={uploadIds.length}
          expanded={expanded}
          onClose={handleClose}
          allowClose={!uploadIds.length}
          handleToggle={() => setExpanded(d => !d)}
        />

        <div
          className={clsx(classes.floatingStatusWidgetBody, {
            [classes.expanded]: expanded,
            [classes.minimised]: !expanded
          })}
        >
          {Object.values(uploadFolders).map(props => {
            return (
              <StatusCard
                key={props.session.upload_task_id}
                onFileUploadStart={onFileUploadStart}
                onFileRetry={onFileRetry}
                {...props}
              />
            );
          })}
          {inferenceWidgetData.map(item => (
            <InferenceCard key={item.upload_task_id} {...item} />
          ))}
        </div>
      </div>
    </Draggable>
  );
});
