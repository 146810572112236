export const ACTION_CONSTS = {
  SET_FILTER_VALUE: 'SET_FILTER_VALUE',
  CLEAR_FILTER_VALUES: 'CLEAR_FILTER_VALUES',
  CLEAR_ALL_FILTERS: 'CLEAR_ALL_FILTERS',
  CLEAR_FILTER_BY_ID: 'CLEAR_FILTER_BY_ID',
  ADD_TO_MORE_FILTER_BY_ID: 'ADD_TO_MORE_FILTER_BY_ID',
  REMOVE_FROM_MORE_FILTER_BY_ID: 'REMOVE_FROM_MORE_FILTER_BY_ID',
  SET_IS_FILTER_LOADING: 'SET_IS_FILTER_LOADING'
};

export const STATE_KEYS = {
  ALL_FILTERS: 'allFilters',
  IS_FILTER_LOADING: 'isFilterLoading'
};
