import clsx from 'clsx';
import classes from './Progressbar.module.scss';

export type ProgressStyle = {
  successTrack?: string;
  errorTrack?: string;
  baseTrack?: string;
};

export type ProgressBarProps = {
  successProgress: number;
  currentProgress: number;
  styles?: ProgressStyle;
};

export const ProgressBar = ({
  successProgress,
  currentProgress,
  styles
}: ProgressBarProps) => {
  return (
    <div className={classes.container}>
      <div
        className={clsx(classes.innerTrack, classes.baseTrack, {
          [styles?.baseTrack || '']: styles?.baseTrack
        })}
      />
      <div
        style={{ width: `${currentProgress}%` }}
        className={clsx(classes.innerTrack, classes.errorTrack, {
          [styles?.errorTrack || '']: styles?.errorTrack
        })}
      />
      <div
        style={{ width: `${successProgress}%` }}
        className={clsx(classes.innerTrack, classes.sucessTrack, {
          [styles?.successTrack || '']: styles?.successTrack
        })}
      />
    </div>
  );
};
