export async function setSessionLogger() {
  const { REACT_APP_LOG_ROCKET_ID } = process.env;

  if (REACT_APP_LOG_ROCKET_ID) {
    const { default: LogRocket } = await import('logrocket');
    LogRocket.init(REACT_APP_LOG_ROCKET_ID);
  }
}

export async function setSessionLoggedUser(user) {
  const {
    REACT_APP_LOG_ROCKET_ID,
    REACT_APP_CUSTOMER,
    REACT_APP_LOG_ROCKET_IDENTIFY
  } = process.env;

  if (REACT_APP_LOG_ROCKET_ID && REACT_APP_LOG_ROCKET_IDENTIFY) {
    const { default: LogRocket } = await import('logrocket');
    LogRocket.identify(user.email, {
      // Add your own custom user variables here, ie:
      ...user,
      customer_tenant_id: REACT_APP_CUSTOMER
    });
  }
}
