import { useCallback, useEffect, useState } from 'react';
import { INFERENCE_STATUS_EVENT, INFERENCE_STATUS_EVENT_ID } from './constants';
import { addInferenceStatus } from './actions';
import useLocalStates from '../useLocalStates';
import { InferenceStatusType } from './reducer';
import { WidgetActions } from '../types';

type UseInferenceStateType = (
  subscribeToStateChange?: boolean
) => [
  InferenceStatusType,
  (params: WidgetActions) => void,
  { addInferenceStatus: typeof addInferenceStatus }
];

const useInferenceStates: UseInferenceStateType = (
  subscribeToStateChange = false
) => {
  const [state, globalDispatch] = useLocalStates(subscribeToStateChange);

  const [, f] = useState({});

  const handleStateChange = useCallback(() => {
    f({});
  }, []);

  useEffect(() => {
    if (subscribeToStateChange) {
      document.addEventListener(INFERENCE_STATUS_EVENT_ID, handleStateChange);

      return () => {
        document.removeEventListener(
          INFERENCE_STATUS_EVENT_ID,
          handleStateChange
        );
      };
    }
  }, []);

  const dispatch = (params: WidgetActions) => {
    // @ts-ignore
    globalDispatch(params);
    document.dispatchEvent(INFERENCE_STATUS_EVENT);
  };

  return [
    state['inferenceStatus'],
    dispatch,
    {
      addInferenceStatus
    }
  ];
};

export default useInferenceStates;
