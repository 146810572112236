import { WidgetActions } from '../types';
import { InferenceTaskType } from './Type';
import { ACTION_CONSTS } from './constants';

export type InferenceStatusType = {
  data: Array<InferenceTaskType>;
};

const getInitialState = () => ({
  data: []
});

export const reducer = (state = getInitialState(), action: WidgetActions) => {
  switch (action.type) {
    case ACTION_CONSTS.ADD_INFERENCE_STATUS:
      return {
        ...state,
        data: [action.payload, ...state['data']]
      };

    default:
      return state;
  }
};
