import initialState from 'store/constants/initial';

import * as t from './constants';
import { DialogVariant } from 'app/utils/SimilarImages/types';

function setSimilarImagesDialog(
  state: typeof initialState.similarImages,
  payload: boolean
) {
  return {
    ...state,
    open: payload
  };
}

function setSimilarImageDialogFilters(
  state: typeof initialState.similarImages,
  payload: Record<string, string | number>
) {
  return {
    ...state,
    filters: payload
  };
}

function resetSimilarImageDialog() {
  return initialState.similarImages;
}

function setDialogVariant(
  state: typeof initialState.similarImages,
  payload: DialogVariant | null
) {
  return {
    ...state,
    dialogVariant: payload
  };
}

function setSimilarImageLoading(
  state: typeof initialState.similarImages,
  payload: boolean
) {
  return { ...state, isLoading: payload };
}

export default function SimilarImageReducer(
  state = initialState.similarImages,
  action: { type: string; payload: any }
) {
  switch (action.type) {
    case t.SET_SIMILAR_IMAGE_DIALOG:
      return setSimilarImagesDialog(state, action.payload);
    case t.SET_SIMILAR_IMAGES_FILTERS:
      return setSimilarImageDialogFilters(state, action.payload);
    case t.RESET_SIMILAR_IMAGE_DIALOG:
      return resetSimilarImageDialog();
    case t.SET_DIALOG_VARIANT:
      return setDialogVariant(state, action.payload);
    case t.SET_SIMILAR_IMAGE_LOADING:
      return setSimilarImageLoading(state, action.payload);
    default:
      return state;
  }
}
