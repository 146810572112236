import { DialogVariant } from 'app/utils/SimilarImages/types';
import {
  RESET_SIMILAR_IMAGE_DIALOG,
  SET_DIALOG_VARIANT,
  SET_SIMILAR_IMAGES_FILTERS,
  SET_SIMILAR_IMAGE_DIALOG,
  SET_SIMILAR_IMAGE_LOADING
} from './constants';

export const setSimilarImageDialog = (payload: boolean) => {
  return { type: SET_SIMILAR_IMAGE_DIALOG, payload };
};

export const setSimilarImageFilters = (
  payload: Record<string, string | number>
) => {
  return { type: SET_SIMILAR_IMAGES_FILTERS, payload };
};

export const resetSimilarImageDialog = () => {
  return { type: RESET_SIMILAR_IMAGE_DIALOG };
};

export const setDialogVariant = (payload: DialogVariant | null) => {
  return { type: SET_DIALOG_VARIANT, payload };
};

export const setSimilartImageLoading = (payload: boolean) => {
  return { type: SET_SIMILAR_IMAGE_LOADING, payload };
};
