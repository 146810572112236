export const ACTION_CONSTS = {
  ADD_UPLOAD_FOLDER: 'ADD_UPLOAD_FOLDER',
  REMOVE_UPLOAD_FOLDER: 'REMOVE_UPLOAD_FOLDER',
  SET_FOLDER_RETRY: 'SET_FOLDER_RETRY',
  SET_FOLDER_ERROR: 'SET_FOLDER_ERROR',
  SET_FOLDER_UPLOAD_COMPLETE: 'SET_FOLDER_UPLOAD_COMPLETE',
  SET_FOLDER_UPLOAD_ID: 'SET_FOLDER_UPLOAD_ID',
  RESET_STATUS_WIDGET: 'RESET_STATUS_WIDGET'
};

export const FOLDER_UPLOAD_EVENT_ID = 'onStateChange_231d2';

export const FOLDER_UPLOAD_EVENT = new Event(FOLDER_UPLOAD_EVENT_ID);
