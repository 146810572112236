export const SET_MODEL_LIB_ACTIVE_STEP = 'SET_MODEL_LIB_ACTIVE_STEP';
export const SET_MODEL_NAME = 'SET_MODEL_NAME';
export const SET_NEW_MODEL = 'SET_NEW_MODEL';
export const SET_EDIT_MODE = 'SET_EDIT_MODE';
export const SET_DIALOG_OPEN = 'SET_DIALOG_OPEN';
export const SET_DIALOG_VARIANT = 'SET_DIALOG_VARIANT';
export const RESET_TRAINING = 'RESET_TRAINING';
export const SET_ACTIVE_IMG = 'SET_ACTIVE_IMG';
export const SET_SELECT_ALL = 'SET_SELECT_ALL';
export const SET_FILE_SET_DATA = 'SET_FILE_SET_DATA';
export const SET_FILE_SET_COUNT = 'SET_FILE_SET_COUNT';
export const SET_MODEL_USECASE = 'SET_MODEL_USECASE';
export const SET_OLD_MODEL = 'SET_OLD_MODEL';
export const SET_SELECTED_DEFECTS = 'SET_SELECTED_DEFECTS';
export const SET_SAME_MODEL_NAME_ERROR = 'SET_SAME_MODEL_NAME_ERROR';
export const SET_FILE_SET_DEFECTS = 'SET_FILE_SET_DEFECTS';
export const SET_FILE_SET_DEFECTS_LOADING = 'SET_FILE_SET_DEFECTS_LOADING';
export const SET_ACTIVE_TRAINING_MODE = 'SET_ACTIVE_TRAINING_MODE';
export const REMOVE_SELECTED_FILES = 'REMOVE_SELECTED_FILES';
export const SET_PENDING_TASK_ID = 'SET_PENDING_TASK_ID';
export const SET_SHOULD_TRAINING_OPEN = 'SET_SHOULD_TRAINING_OPEN';
export const SET_RADIO_CONFIG = 'SET_RADIO_CONFIG';
export const SET_TRAINING_PROGRESS_VALUE = 'SET_TRAINING_PROGRESS_VALUE';
export const SET_EPOCH_COUNT = 'SET_EPOCH_COUNT';
export const SET_IMAGE_RESOLUTION = 'SET_IMAGE_RESOLUTION';
export const RESET_RADIO_CONFIG = 'RESET_RADIO_CONFIG';
export const SET_TRAINING_COUNT = 'SET_TRAINING_COUNT';
export const SET_TRAINING_CONFIG = 'SET_TRAINING_CONFIG';
export const SET_TRAINING_DATA_INFORMATION = 'SET_TRAINING_DATA_INFORMATION';
export const SET_IS_VIEW_DETAILS = 'SET_IS_VIEW_DETAILS';
export const SET_FETCHING_TRAINING_FILESETS = 'SET_FETCHING_TRAINING_FILESETS';
export const SET_TRAINING_FILESET_NEXT_API = 'SET_TRAINING_FILESET_NEXT_API';
export const SET_MODEL_TRAINING_CONTAINER_META =
  'SET_MODEL_TRAINING_CONTAINER_META';
export const SET_DEFECT_INSTANCES_COUNT = 'SET_DEFECT_INSTANCES_COUNT';

export const ADDED_FOR_TRAINING = 'Added for Training';
export const NOT_ADDED_FOR_TRAINING = 'Not Added for Training';

export const RESET_MODEL_TRAINING = 'RESET_MODEL_TRAINING';

export const UPDATE_MODEL_FILESET_TAG_BY_INDEX =
  'UPDATE_MODEL_FILESET_TAG_BY_INDEX';
