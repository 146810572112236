import { apiCallBack } from '../ApiMethods';
import { InferenceStatusParams, InferenceStatusResponse } from './types';

import { encodeURL, getDateFromParams } from 'app/utils/helpers';

export const getWaferMapById = (params: ApiBase<{ id: number }>) => {
  const { id } = params.baseParams;

  return apiCallBack({
    method: 'GET',
    url: `/api/v1/classif-ai/wafer-map/${id}/`,
    ...params
  }).then(_ => _.data);
};

export const getInferenceStatus = (params: ApiBase<InferenceStatusParams>) => {
  return apiCallBack<InferenceStatusParams, unknown, InferenceStatusResponse>({
    method: 'GET',
    url: `/api/v1/classif-ai/file-set-inference-queue/progress_status/`,
    ...params,
    transformParams: baseParams => {
      const {
        ml_model_id__in,
        useLocationParams = false,
        ...rest
      } = baseParams as unknown as object & {
        ml_model_id__in: number;
        useLocationParams?: boolean;
        upload_session_id__in: string;
      };

      return {
        file_set_filters: encodeURL(
          useLocationParams
            ? getDateFromParams(rest, undefined, true)
            : {
                upload_session_id__in: rest.upload_session_id__in
              }
        ),
        ml_model_id__in
      };
    }
  }).then(_ => _.data);
};
