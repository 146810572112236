import { WidgetActions } from '../types';
import { UploadFolderState, UploadSession } from './Types';
import { ACTION_CONSTS } from './constants';

const getInitialState = (): UploadFolderState => ({
  uploadFolders: {},
  uploadIds: []
});

export const reducer = (state = getInitialState(), action: WidgetActions) => {
  switch (action.type) {
    case ACTION_CONSTS.ADD_UPLOAD_FOLDER:
      return {
        ...state,
        uploadFolders: {
          [(action.payload as UploadSession).session.upload_task_id]: {
            ...action.payload,
            isRetry: false,
            isComplete: false,
            isError: false
          },
          ...state['uploadFolders']
        }
      };

    case ACTION_CONSTS.REMOVE_UPLOAD_FOLDER:
      delete state.uploadFolders[action.payload as number];
      return { ...state };

    case ACTION_CONSTS.SET_FOLDER_RETRY:
      state.uploadFolders[action.payload as number] = {
        ...state.uploadFolders[action.payload as number],
        isError: false
      };
      return {
        ...state,
        uploadIds: [action.payload, ...state['uploadIds']]
      };

    case ACTION_CONSTS.SET_FOLDER_ERROR:
      state.uploadFolders[action.payload as number] = {
        ...state.uploadFolders[action.payload as number],
        isError: true,
        isRetry: true
      };
      return {
        ...state,
        uploadIds: state['uploadIds'].filter(d => d !== action.payload)
      };

    case ACTION_CONSTS.SET_FOLDER_UPLOAD_COMPLETE:
      state.uploadFolders[action.payload as number] = {
        ...state.uploadFolders[action.payload as number],
        isRetry: false,
        isError: false,
        isComplete: true
      };
      return {
        ...state,
        uploadIds: state['uploadIds'].filter(d => d !== action.payload)
      };

    case ACTION_CONSTS.SET_FOLDER_UPLOAD_ID:
      return {
        ...state,
        uploadIds: [action.payload, ...state['uploadIds']]
      };

    case ACTION_CONSTS.RESET_STATUS_WIDGET:
      return getInitialState();

    default:
      return state;
  }
};
