import { WidgetActions } from '../types';
import { FolderType } from './Types';

export function addUploadFolder(folder: FolderType): WidgetActions {
  return { type: 'ADD_UPLOAD_FOLDER', payload: folder };
}

export function setFolderRetry(id: number): WidgetActions {
  return { type: 'SET_FOLDER_RETRY', payload: id };
}

export function setFolderError(id: number): WidgetActions {
  return { type: 'SET_FOLDER_ERROR', payload: id };
}

export function setFolderUploadComplete(id: number): WidgetActions {
  return { type: 'SET_FOLDER_UPLOAD_COMPLETE', payload: id };
}

export function setFolderUploadId(id: number): WidgetActions {
  return { type: 'SET_FOLDER_UPLOAD_ID', payload: id };
}

export function resetStatusWidget(): WidgetActions {
  return { type: 'RESET_STATUS_WIDGET' };
}
