import { DialogVariant } from 'app/utils/SimilarImages/types';
import { createSelector } from 'reselect';
import { RootState } from 'store';
import initialState from 'store/constants/initial';
import { STORE_KEYS } from 'store/storeKeys';

const selectSimilarImagesDialog = (state: RootState) =>
  state[STORE_KEYS.SIMILAR_IMAGES_DIALOG] as typeof initialState.similarImages;

export const selectIsSimilarImageDialogOpen = createSelector(
  [selectSimilarImagesDialog],
  similarImageDialog => similarImageDialog.open
);

export const selectSimilarImageDialogFilters = createSelector(
  [selectSimilarImagesDialog],
  similarImageDialog => similarImageDialog.filters
);

export const selectDialogVariant = createSelector(
  [selectSimilarImagesDialog],
  similarImageDialog => similarImageDialog.dialogVariant as DialogVariant | null
);

export const selectIsSimilarImageLoading = createSelector(
  [selectSimilarImagesDialog],
  similarImageDialog => similarImageDialog.isLoading as boolean
);
