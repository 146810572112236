import { useCallback, useEffect, useState } from 'react';
import { FOLDER_UPLOAD_EVENT, FOLDER_UPLOAD_EVENT_ID } from './constants';
import {
  addUploadFolder,
  setFolderRetry,
  setFolderError,
  setFolderUploadComplete,
  setFolderUploadId,
  resetStatusWidget
} from './actions';
import useLocalStates from '../useLocalStates';
import { WidgetActions } from '../types';

const useFolderUploadStates = (subscribeToStateChange = false) => {
  const [state, globalDispatch] = useLocalStates(subscribeToStateChange);

  const [, f] = useState({});

  const handleStateChange = useCallback(() => {
    f({});
  }, []);

  useEffect(() => {
    if (subscribeToStateChange) {
      document.addEventListener(FOLDER_UPLOAD_EVENT_ID, handleStateChange);

      return () => {
        document.removeEventListener(FOLDER_UPLOAD_EVENT_ID, handleStateChange);
      };
    }
  }, []);

  const dispatch = (params: WidgetActions) => {
    // @ts-ignore
    globalDispatch(params);
    document.dispatchEvent(FOLDER_UPLOAD_EVENT);
  };

  return [
    state['folderUpload'],
    dispatch,
    {
      addUploadFolder,
      setFolderRetry,
      setFolderError,
      setFolderUploadComplete,
      setFolderUploadId,
      resetStatusWidget
    }
  ];
};

export default useFolderUploadStates;
