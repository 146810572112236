const colors = {
  grey: {
    0: '#FFFFFF',
    1: '#F0F7FF',
    2: '#E8F2FE',
    3: '#E0ECFD',
    4: '#CEE0F8',
    5: '#BBD2F1',
    6: '#A8C3E8',
    7: '#95B2DD',
    8: '#82A0CE',
    9: '#6F8EBD',
    10: '#5E7BAA',
    11: '#4D6896',
    12: '#3E5680',
    13: '#31456A',
    14: '#263755',
    15: '#1C2A42',
    16: '#162236',
    17: '#0E1623',
    18: '#090F17',
    19: '#05090E',
    20: '#030407',
    21: '#010202',
    22: '#000000',
    23: '#323648',
    900: '#111827'
  },
  opacity: {
    10: 1,
    9: 0.9,
    8: 0.8,
    7: 0.7,
    6: 0.6,
    5: 0.5,
    4: 0.4,
    3: 0.3,
    2: 0.2,
    1: 0.1,
    0: 0
  },
  yellow: {
    900: '#78350F',
    800: '#92400E',
    700: '#B45309',
    600: '#D97706',
    500: '#F59E0B',
    400: '#FBBF24',
    300: '#FCD34D',
    200: '#FDE68A',
    100: '#FEF3C7',
    50: '#FFFBEB'
  },
  green: {
    900: '#064E3B',
    800: '#065F46',
    700: '#047857',
    600: '#059669',
    500: '#10B981',
    400: '#34D399',
    300: '#6EE7B7',
    200: '#A7F3D0',
    100: '#D1FAE5',
    50: '#ECFDF5'
  },
  teal: {
    900: '#134E4A',
    800: '#115E59',
    700: '#0F766E',
    600: '#0D9488',
    500: '#14B8A6',
    400: '#2DD4BF',
    300: '#5EEAD4',
    200: '#99F6E4',
    100: '#CCFBF1',
    50: '#F0FDFA'
  },
  blue: {
    900: '#1E3A8A',
    800: '#1E40AF',
    700: '#1D4ED8',
    600: '#2563EB',
    500: '#3B82F6',
    400: '#60A5FA',
    300: '#93C5FD',
    200: '#BFDBFE',
    100: '#DBEAFE',
    50: '#EFF6FF'
  },
  indigo: {
    900: '#292689',
    800: '#3730A3',
    700: '#4338CA',
    600: '#4F46E5',
    500: '#6366F1',
    400: '#818CF8',
    300: '#A5B4FC',
    200: '#C7D2FE',
    100: '#E0E7FF',
    50: '#EEF2FF'
  },
  purple: {
    900: '#4C1D95',
    800: '#5B21B6',
    700: '#6D28D9',
    600: '#7C3AED',
    500: '#8B5CF6',
    400: '#A78BFA',
    300: '#C4B5FD',
    200: '#DDD6FE',
    100: '#EDE9FE',
    50: '#F5F3FF'
  },
  violet: {
    900: '#4C1D95',
    800: '#5B21B6',
    700: '#6D28D9',
    600: '#7C3AED',
    500: '#8B5CF6',
    400: '#A78BFA',
    300: '#C4B5FD',
    200: '#DDD6FE',
    100: '#EDE9FE',
    50: '#F5F3FF'
  },
  pink: {
    900: '#831843',
    800: '#9D174D',
    700: '#BE185D',
    600: '#DB2777',
    500: '#EC4899',
    400: '#F472B6',
    300: '#F9A8D4',
    200: '#FBCFE8',
    100: '#FCE7F3',
    50: '#FDF2F8'
  },
  red: {
    900: '#881337',
    800: '#9F1239',
    700: '#BE123C',
    600: '#E11D48',
    500: '#F43F5E',
    400: '#FB7185',
    300: '#FDA4AF',
    200: '#FECDD3',
    100: '#FFE4E6',
    50: '#FFF1F2'
  },
  shadow: {
    sm: '0px 1px 2px rgba(14, 22, 35, 0.05)',
    base: '0px 1px 2px rgba(14, 22, 35, 0.06), 0px 1px 3px rgba(14, 22, 35, 0.1)',
    md: '0px 2px 4px rgba(14, 22, 35, 0.06), 0px 4px 6px rgba(14, 22, 35, 0.1)',
    lg: '0px 4px 6px rgba(14, 22, 35, 0.05) ,0px 10px 15px rgba(14, 22, 35, 0.1)',
    xl: '0px 10px 10px rgba(14, 22, 35, 0.04) ,0px 20px 25px rgba(14, 22, 35, 0.1)',
    '2xl': '0px 25px 50px rgba(14, 22, 35, 0.25)',
    'diffuse-card': '0px 25px 50px rgba(14, 22, 35, 0.08)'
  }
};
export default colors;
