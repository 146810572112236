import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import api from 'app/api';
import axios from 'axios';
import CommonBackdrop from 'app/components/CommonBackdrop';
import { updateNextDataURL } from 'app/utils/helpers';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { VariableSizeList as List } from 'react-window';
import InfiniteScroll from 'react-window-infinite-loader';
import store from 'store/index';
import { setPendingTasksIds } from 'store/taskQueue/actions';

import { useWindowResize } from 'app/hooks/useWindowResize';
import { Row } from './Row';
import { updatePendingTasks } from 'app/utils/taskQueue';

const useStyles = makeStyles(theme => ({
  scrollableDiv: {
    '& .List': {
      '&::-webkit-scrollbar ': {
        width: 3
      },

      /* Track */
      '&::-webkit-scrollbar-track': {
        borderRadius: 10
      },

      /* Handle */
      '&::-webkit-scrollbar-thumb': {
        background: '#31456A',
        borderRadius: 10
      },

      /* Handle on hover */
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#EEEEEE'
      }
    }
  }
}));

const FailedMode = () => {
  let cancelToken;

  const classes = useStyles();
  const dispatch = useDispatch();

  const [isGettingTasks, setIsGettingTasks] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTasksLoading, setIsTasksLoading] = useState(true);
  const [tasks, setTasks] = useState([]);

  const nextPointRef = useRef(null);

  const { pendingStatusIds } = useSelector(({ TaskQueue }) => TaskQueue);

  const [, windowHeight] = useWindowResize();

  const listRef = useRef();
  const sizeMap = useRef({});

  const setSize = useCallback((index, size) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    listRef.current.resetAfterIndex(index);
  }, []);

  useEffect(() => {
    api
      .getAllTasks('', `?status=FAILURE&cursor=`)
      .then(res => {
        const { results, next } = res;
        nextPointRef.current = updateNextDataURL(next);
        setTasks(results);
      })
      .finally(() => {
        setIsTasksLoading(false);
      });
  }, []);

  useEffect(() => {
    dispatch(
      setPendingTasksIds(
        tasks
          .filter(x => x.status === 'PENDING' || x.status === 'STARTED')
          .map(x => x.task_id)
      )
    );
  }, [tasks]);

  const { data: pendingTasksData } = useQuery(
    ['pendingTasksData', `?task_id__in=${pendingStatusIds.join(',')}&cursor=`],
    context => api.getAllTasks(...context.queryKey),
    { enabled: !!pendingStatusIds.length, refetchInterval: 1000 * 5 }
  );

  useEffect(() => {
    if (pendingTasksData?.results) {
      setTasks(d => updatePendingTasks(d, pendingTasksData.results));
    }
  }, [pendingTasksData]);

  const ele = document.getElementById('scrollableDiv');

  const [compSize, setCompSize] = useState({
    width: 400,
    height: ele?.offsetHeight
  });

  useEffect(() => {
    if (ele?.offsetHeight) {
      setCompSize({ ...compSize, height: ele?.offsetHeight });
    }
  }, [ele]);

  useEffect(() => {
    function handleResize() {
      const ele = document.getElementById('scrollableDiv');
      setCompSize(d => ({ ...d, width: 400, height: ele?.offsetHeight }));
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleRetryClick = id => {
    setIsLoading(true);

    api
      .retryTask(id)
      .then(() => {
        const idx = tasks.findIndex(d => d.task_id === id);

        if (idx > -1) {
          setTasks(d => {
            d[idx].status = 'PENDING';
            return [...d];
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const isItemLoaded = index => !!tasks[index];

  const loadMoreFiles = () => {
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Cancel the prev request.');
    }

    if (!!nextPointRef.current && !isGettingTasks) {
      const { accessToken } = store.getState().user;

      cancelToken = axios.CancelToken.source();

      setIsGettingTasks(true);

      return axios
        .get(nextPointRef.current, {
          cancelToken: cancelToken.token,
          headers: { Authorization: `Bearer ${accessToken}` }
        })
        .then(({ data: res }) => {
          const { results = [], next } = res;

          nextPointRef.current = updateNextDataURL(next);
          setTasks(d => [...d, ...results]);
        })
        .catch(e => {
          if (axios.isCancel(e)) return;
        })
        .finally(() => {
          setIsGettingTasks(false);
        });
    }

    return new Promise(resolve => {
      resolve();
    });
  };

  const getItemSize = index => {
    return sizeMap.current[index] || 50;
  };

  return (
    <Box
      flex={1}
      width='100%'
      height='100%'
      id='scrollableDiv'
      className={classes.scrollableDiv}
    >
      <InfiniteScroll
        isItemLoaded={isItemLoaded}
        itemCount={tasks.length + 1}
        loadMoreItems={loadMoreFiles}
      >
        {({ onItemsRendered }) => (
          <>
            <List
              className='List'
              height={compSize.height || 0}
              itemCount={tasks.length}
              itemSize={getItemSize}
              onItemsRendered={onItemsRendered}
              ref={listRef}
              width={400}
            >
              {props => (
                <Row
                  {...props}
                  tasks={tasks}
                  setSize={setSize}
                  windowHeight={windowHeight}
                  handleRetryClick={handleRetryClick}
                />
              )}
            </List>

            {isGettingTasks && tasks.length > 0 ? (
              <div style={{ textAlign: 'center' }}>
                <h5>Loading...</h5>
              </div>
            ) : null}
          </>
        )}
      </InfiniteScroll>
      <CommonBackdrop open={isTasksLoading || isLoading} />
    </Box>
  );
};

export default FailedMode;
