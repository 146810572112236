import { useCallback, useEffect, useState } from 'react';
import { reducer as FolderReducer } from './FolderUpload/reducer';
import {
  reducer as FloatingWidgetReducer,
  FloatingWidgetType
} from './FloatingWidget/reducer';
import {
  reducer as InferenceStatusReducer,
  InferenceStatusType
} from './InferenceStatus/reducer';
import { combineReducers } from 'redux';
import { UploadFolderState } from './FolderUpload/Types';
import { WidgetActions } from './types';

const EVENT_ID = 'onLocalStateChange_231d2';

const event = new Event(EVENT_ID);

type InitialState = {
  floatingWidget: FloatingWidgetType;
  folderUpload: UploadFolderState;
  inferenceStatus: InferenceStatusType;
};

let state: InitialState = {
  floatingWidget: {
    isWidgetOpen: false
  },
  folderUpload: {
    uploadFolders: {},
    uploadIds: []
  },
  inferenceStatus: {
    data: []
  }
};

const rootReducer = combineReducers({
  floatingWidget: FloatingWidgetReducer,
  folderUpload: FolderReducer,
  inferenceStatus: InferenceStatusReducer
});

const useLocalStates = <T extends WidgetActions>(
  subscribeToStateChange = false
) => {
  const [, f] = useState({});

  const handleStateChange = useCallback(() => {
    f({});
  }, []);

  useEffect(() => {
    if (subscribeToStateChange) {
      document.addEventListener(EVENT_ID, handleStateChange);

      return () => {
        document.removeEventListener(EVENT_ID, handleStateChange);
      };
    }
  }, []);

  const dispatch = (action: WidgetActions) => {
    // @ts-ignore
    state = rootReducer(state, action);
    document.dispatchEvent(event);
  };

  return [state, dispatch] as [InitialState, (action: T) => void];
};

export default useLocalStates;
