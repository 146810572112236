import Regular400 from './fonts/IBMPlexSans-Regular.ttf';
import Medium500 from './fonts/IBMPlexSans-Medium.ttf';
import SemiBold600 from './fonts/IBMPlexSans-SemiBold.ttf';
import Bold700 from './fonts/IBMPlexSans-Bold.ttf';

const IBMPlexBold = {
  fontFamily: 'IBMPlexBold',
  src: `
    local(IBMPlex-Bold),
    url(${Bold700}) format("truetype");`
};

const IBMPlexRegular = {
  fontFamily: 'IBMPlexRegular',
  src: `
    local(IBMPlex-Regular),
    url(${Regular400}) format("truetype");`
};
const IBMPlexMedium = {
  fontFamily: 'IBMPlexMedium',
  src: `
    local(IBMPlex-Medium),
    url(${Medium500}) format("truetype");`
};
const IBMPlexSemiBold = {
  fontFamily: 'IBMPlexSemiBold',
  src: `
    local(IBMPlex-Semi-Bold),
    url(${SemiBold600}) format("truetype");`
};

export default {
  IBMPlexBold,
  IBMPlexRegular,
  IBMPlexMedium,
  IBMPlexSemiBold
};
