import { apiCallBack } from '../ApiMethods';
import {
  GenerateStatusResponse,
  GetSimilarImageParams,
  GetSimilarImageTaskModelParams,
  GetSimilarImageTaskModelResponse
} from './types';

export const generateSimilarImagesForMisclassification = (
  params: GetSimilarImageParams
) => {
  return apiCallBack({
    method: 'POST',
    url: `/api/v2/classif-ai/similar-image/task/misclassification-pair/`,
    data: params
  }).then(_ => _.data);
};

export const generateSimilarImagesForReview = (
  params: GetSimilarImageParams
) => {
  return apiCallBack({
    method: 'POST',
    url: `/api/v2/classif-ai/similar-image/task/`,
    data: params
  }).then(_ => _.data);
};

export const getGenerateSimilarImageStatus = (
  params: GetSimilarImageParams
) => {
  return apiCallBack({
    method: 'GET',
    url: `/api/v2/classif-ai/similar-image/generate-status/`,
    baseParams: params
  }).then(_ => _.data as GenerateStatusResponse);
};

export const getSimilarImageTaskModel = (
  params: GetSimilarImageTaskModelParams
) => {
  return apiCallBack({
    method: 'GET',
    url: `/api/v2/classif-ai/similar-image/`,
    baseParams: params
  }).then(_ => {
    const { results } =
      _.data as PaginationResponse<GetSimilarImageTaskModelResponse>;

    return results.reduce(
      (prev, curr) => ({ ...prev, [curr.task]: curr }),
      {}
    ) as Record<
      GetSimilarImageTaskModelResponse['task'],
      GetSimilarImageTaskModelResponse
    >;
  });
};
