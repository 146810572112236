import {
  SET_NOTIFICATION_COUNT,
  RESET_UNREAD_NOTIFICATION
} from './constants';

export const setNotificationCount = data => {
  return { type: SET_NOTIFICATION_COUNT, payload: data };
};

export const resetUnreadNotification = () => {
  return { type: RESET_UNREAD_NOTIFICATION };
};
