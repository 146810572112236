import { keyBy, orderBy, sortBy } from 'lodash';
import initialState from 'store/constants/initial';

import * as t from './constants';


function resetUnreadNotification(state) {
  return {
    ...state,
    unread: 0
  };
}

function setNotificationCount(state, payload) {
  return {
    ...state,
    total: payload?.total || 0,
    read: payload?.is_read || 0,
    unread: payload?.is_unread || 0
  };
}

export default function (state = initialState.notifications, action) {
  switch (action.type) {
    case t.SET_NOTIFICATION_COUNT:
      return setNotificationCount(state, action.payload);
    case t.RESET_UNREAD_NOTIFICATION:
      return resetUnreadNotification(state);

    default:
      return state;
  }
}
