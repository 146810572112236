export const TRANSLATION_KEYS = {
  COMMON: 'COMMON',
  LOGIN: 'LOGIN',
  UseCaseLibrary: 'UseCaseLibrary',
  FILTERS: 'FILTERS',
  DEFECT_LIBRARY: 'defectLibrary',
  MODEL_LIBRARY: 'modelLibrary',
  AI_LIBRARY: 'aiLibrary',
  DATA_LIBRARY: 'dataLibrary',
  REVIEW: 'review'
};
