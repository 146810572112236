import TaskCard from '../taskCard';

const Row = ({
  index,
  key,
  style,
  tasks,
  handleRetryClick = () => null,
  setSize,
  windowHeight,
  ...rest
}) => {
  const { description, status, task_name, url } = tasks[index];

  if (!tasks[index]?.status) {
    return null;
  }

  return (
    <TaskCard
      {...rest}
      style={{
        ...style,
        left: style.left,
        top: style.top,
        width: style.width,
        height: style.height
      }}
      key={tasks[index].task_id}
      taskId={tasks[index].task_id}
      title={description}
      taskName={task_name}
      index={index}
      url={url}
      // timestamp={tasks[index].timestamp}
      status={status}
      onRetryClick={() => handleRetryClick(tasks[index].task_id)}
      windowHeight={windowHeight}
      setSize={setSize}
    />
  );
};

export { Row };
