import { Dialog, Paper, Typography } from '@material-ui/core';
import CommonButton from '../ReviewButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

import classes from './SimilarImagesDialog.module.scss';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  selectIsSimilarImageDialogOpen,
  selectSimilarImageDialogFilters
} from 'store/SimilarImages/selectors';
import { useDispatch } from 'react-redux';
import { resetSimilarImageDialog } from 'store/SimilarImages/actions';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';
import { encodeURL } from 'app/utils/helpers';
import SSRadioGroup from '../SSRadioGroup';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_KEYS } from 'app/constants/transalations';

const DialogValues = {
  match_ai_level_with_training_gt: 'match_ai_level_with_training_gt',
  all_similar_images: 'all_similar_images'
};

const RADIO_CONSTANT = [
  {
    label:
      'Similar images with user label identical to AI prediction on test image',
    subLabel: 'It’s an efficient way to update the mislabelled training images',
    value: DialogValues.match_ai_level_with_training_gt
  },
  {
    label: 'All similar images',
    subLabel: 'It’s helpful when similar images are confusing & mislabelled',
    value: DialogValues.all_similar_images
  }
];

const mapStateToSimilarImageDialog = createStructuredSelector({
  open: selectIsSimilarImageDialogOpen,
  filters: selectSimilarImageDialogFilters
});

export const SimilarImageDialog = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(TRANSLATION_KEYS.REVIEW);
  const { subscriptionId, packId } = useParams();
  const { open, filters } = useSelector(mapStateToSimilarImageDialog);
  const [radioValue, setRadioValue] = useState(
    DialogValues.match_ai_level_with_training_gt
  );

  const onChange = (value: string) => {
    setRadioValue(value);
  };

  const handleDialogClose = () => {
    dispatch(resetSimilarImageDialog());
  };

  const handleViewClick = () => {
    const payload: { [x: string]: any } = {
      ...filters,
      is_similar_image_view: true
    };
    if (radioValue === DialogValues.match_ai_level_with_training_gt) {
      payload.match_ai_level_with_training_gt = true;
    }

    const params = queryString.stringify(
      { contextual_filters: encodeURL(payload) },
      { arrayFormat: 'comma' }
    );
    handleDialogClose();

    window.open(
      `/${subscriptionId}/${packId}/annotation/review?${params}`,
      '_blank'
    );
  };

  return (
    <Dialog className={classes.container} open={open}>
      <Paper className={classes.root}>
        <div className={classes.header}>
          <Typography>{t('View similar image')}</Typography>
          <FontAwesomeIcon
            onClick={handleDialogClose}
            className={classes.closeIcon}
            icon={faTimes}
          />
        </div>
        <div className={classes.body}>
          <SSRadioGroup
            config={{
              value: radioValue,
              onChange: onChange,
              styles: {
                label: classes.radioLabel,
                subLabel: classes.radioSubLabel
              }
            }}
            options={RADIO_CONSTANT?.map(opt => ({
              ...opt,
              label: opt.label,
              subLabel: opt.subLabel
            }))}
          />
        </div>
        <div className={classes.actionBtnContainer}>
          <CommonButton onClick={handleViewClick} text={t('View')} />
        </div>
      </Paper>
    </Dialog>
  );
};
