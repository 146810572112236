import { Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';

import classes from './InfernceProgress.module.scss';
import { ProgressBar, ProgressStyle } from './Progressbar';
import Show from 'app/hoc/Show';
import WithCondition from 'app/hoc/WithCondition';

type InferenceProgressProps = {
  success: number;
  failed: number;
  total: number;
  styles?: ProgressStyle;
};

export const InferenceProgress = ({
  success,
  failed,
  total,
  styles
}: InferenceProgressProps) => {
  const totalProgress = ((success + failed) * 100) / total;
  const failedProgress = (failed * 100) / (failed + success) || 0;
  const isAllFailed = failed === total;
  return (
    <div className={classes.root}>
      <div className={classes.detailContainer}>
        <Typography>
          <span className={classes.percentageValue}>
            {Math.round(totalProgress)}%
          </span>{' '}
          complete
        </Typography>

        <Show when={failed}>
          <div className={classes.failedContainer}>
            <FontAwesomeIcon icon={faTriangleExclamation} />
            <Typography>
              <WithCondition
                when={isAllFailed}
                then='All'
                or={<span className={classes.percentageValue}>{failed}</span>}
              />{' '}
              images failed
            </Typography>
          </div>
        </Show>
      </div>
      <ProgressBar
        successProgress={totalProgress - failedProgress}
        currentProgress={totalProgress}
        styles={styles}
      />
    </div>
  );
};
