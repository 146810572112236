import i18n from 'app/utils/i18n';
import { Trans, useTranslation } from 'react-i18next';

const Translate = ({
  children = '',
  ns,
  key = children,
  isComponent = false,
  Component = null,
  translate = true,
  ...rest
}) => {
  const { t } = useTranslation(ns);
  if (!translate) return <span>{children}</span>;
  return (
    <Trans
      i18nKey={key}
      key={key}
      transSupportBasicHtmlNodes
      transWrapTextNodes
      i18n={i18n}
      ns={ns}
      t={t}
      {...rest}
      {...(isComponent
        ? {
            components: {
              CustomLink: <Component />
            }
          }
        : {})}
    >
      {!isComponent && children}
    </Trans>
  );
};

export default Translate;
