import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress } from '@material-ui/core';
import { getSimilarImageTaskModel } from 'app/api/SimilarImages';
import CommonButton from 'app/components/ReviewButton';
import { DATE_RANGE_KEYS } from 'app/constants/filters';
import WithCondition from 'app/hoc/WithCondition';
import { errorToast } from 'app/utils/toast';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  setSimilarImageDialog,
  setSimilarImageFilters
} from 'store/SimilarImages/actions';

type ViewSimilarImagesButtonProps = { taskId: string };

export const ViewSimilarImagesButton = ({
  taskId
}: ViewSimilarImagesButtonProps) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleViewSimilarImageClick = () => {
    setIsLoading(true);
    getSimilarImageTaskModel({ task__in: taskId })
      .then(res => {
        if (res[taskId]) {
          const { ml_model } = res[taskId];
          dispatch(setSimilarImageDialog(true));
          dispatch(
            setSimilarImageFilters({
              similar_image_task_id__in: taskId,
              ml_model_id__in: ml_model,
              date: DATE_RANGE_KEYS.ALL_DATE_RANGE
            })
          );
        } else {
          errorToast(
            'Something went wrong. Please generate the similar images again.'
          );
        }
      })
      .catch(() => {
        errorToast('Failed to fetch model of the given task.');
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <WithCondition
        when={isLoading}
        then={<CircularProgress size={15} />}
        or={
          <CommonButton
            text='View similar image'
            onClick={handleViewSimilarImageClick}
            variant='tertiary'
            icon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
          />
        }
      />
    </>
  );
};
