import { memo } from 'react';
import clsx from 'clsx';

import Show from 'app/hoc/Show';

import classes from './SSRadioGroup.module.scss';

const SSRadioGroup = ({
  config,
  options,
  disabled = false,
  direction = 'row'
}) => {
  const { value: currentValue, onChange, styles = {}, key } = config;

  return (
    <div
      className={clsx(classes.radioGroup, styles.root)}
      disabled={disabled}
      direction={direction}
    >
      {options.map(({ value, label, subLabel }) => {
        const id = `${label}_${value}`;

        return (
          <div
            className={clsx(classes.radioContainer, styles.optionRoot)}
            key={value}
            onClick={e => onChange(value, key)}
          >
            <div className={classes.radioBtnContainer}>
              <input
                id={id}
                type='radio'
                value={value}
                checked={value === currentValue}
                className={clsx(classes.radioBtn, styles.radioBtn)}
                readOnly
              />
            </div>

            <div>
              <label className={clsx(classes.label, styles.label)}>
                {label}
              </label>

              <Show when={subLabel}>
                <label className={clsx(classes.subLabel, styles.subLabel)}>
                  {subLabel}
                </label>
              </Show>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default memo(
  SSRadioGroup,
  (prevProps, nextProps) =>
    nextProps.config.value === prevProps.config.value &&
    nextProps.disabled === prevProps.disabled
);
