import { useCallback, useEffect, useState } from 'react';
import { FLOATING_WIDGET_EVENT, FLOATING_WIDGET_EVENT_ID } from './constants';
import { setStatusWidget } from './actions';
import useLocalStates from '../useLocalStates';
import { WidgetActions } from '../types';

const useFloatingWidgetStates = (subscribeToStateChange = false) => {
  const [state, globalDispatch] = useLocalStates(subscribeToStateChange);

  const [, f] = useState({});

  const handleStateChange = useCallback(() => {
    f({});
  }, []);

  useEffect(() => {
    if (subscribeToStateChange) {
      document.addEventListener(FLOATING_WIDGET_EVENT_ID, handleStateChange);

      return () => {
        document.removeEventListener(
          FLOATING_WIDGET_EVENT_ID,
          handleStateChange
        );
      };
    }
  }, []);

  const dispatch = (params: WidgetActions) => {
    // @ts-ignore
    globalDispatch(params);
    document.dispatchEvent(FLOATING_WIDGET_EVENT);
  };

  return [
    state['floatingWidget'],
    dispatch,
    {
      setStatusWidget
    }
  ];
};

export default useFloatingWidgetStates;
