import { t } from 'i18next';
import { toast, type Id, type ToastOptions } from 'react-toastify';

const toastMeta: { id: null | Id } = { id: null };

export function errorToast(
  msg: string,
  options?: ToastOptions<{}> | undefined
) {
  dismissAllToast();
  toastMeta.id = toast.error(t(msg), options);
}

export function successToast(
  msg: string,
  options?: ToastOptions<{}> | undefined
) {
  dismissAllToast();
  toastMeta.id = toast.success(t(msg), options);
}

export function infoToast(msg: string, options?: ToastOptions<{}> | undefined) {
  dismissAllToast();
  toastMeta.id = toast(t(msg), options);
}

export function warningToast(
  msg: string,
  options?: ToastOptions<{}> | undefined
) {
  dismissAllToast();
  toastMeta.id = toast.warning(t(msg), options);
}

function dismissAllToast() {
  if (toastMeta.id) {
    toast.clearWaitingQueue();
    toast.dismiss(toastMeta.id);
  }
}
