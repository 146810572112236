import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './InferenceCard.module.scss';
import {
  faCircleCheck,
  faLoader,
  faTriangleExclamation
} from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import CommonButton from 'app/components/ReviewButton';
// import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import Show from 'app/hoc/Show';
import { MlModel } from 'app/api/MlModel/types';
import { FolderType } from 'app/hooks/FloatingWidgetHooks/FolderUpload/Types';
import { useCallback, useEffect, useState } from 'react';
import useApi from 'app/hooks/useApi';
import { getInferenceStatus } from 'app/api/fileSet';
import { InferenceProgress } from 'app/components/InferenceProgress';
import {
  getFileSetLocationParams,
  getDefect,
  resetReviewData,
  setAppliedForAllModelId,
  setModelVisibilityObj
} from 'store/reviewData/actions';
import { useDispatch } from 'react-redux';
import { isSameFilteredLocation } from 'app/utils/reviewData';
import { clearAllFilters } from 'store/filter/actions';
import store from 'store';
import { InferenceStatusResponse } from 'app/api/fileSet/types';
import { getParamsObjFromEncodedString } from 'app/utils/helpers';

type StatusIcon = {
  icon: IconProp;
  styles: string;
};

type Status = 'success' | 'pending' | 'failed';

const STATUS_ICON: Record<Status, StatusIcon> = {
  success: {
    icon: faCircleCheck,
    styles: styles.successIcon
  },
  pending: {
    icon: faLoader,
    styles: styles.pendingIcon
  },
  failed: {
    icon: faTriangleExclamation,
    styles: styles.failedIcon
  }
};

type InferenceCardProps = {
  model: MlModel;
  folders?: Array<FolderType>;
  imageCount?: number;
  url?: string;
  params?: string;
  apiParams:
    | { upload_session_id__in: Array<number> }
    | { id__in: Array<number> };
};

type InferenceProgressType = InferenceStatusResponse;

export const InferenceCard = ({
  model,
  folders,
  imageCount,
  url,
  params,
  apiParams
}: InferenceCardProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isInferenceInProgress, setIsInferenceInProgress] = useState(true);

  const { id: modelId, name: modelName } = model;

  const { data: inferenceProgress } = useApi(
    getInferenceStatus,
    {
      ml_model_id__in: modelId,
      ...(apiParams || {}),
      ...(getParamsObjFromEncodedString(params) || {}),
      allowedKeys: []
    },
    {
      enabled: isInferenceInProgress,
      refetchInterval: 5000
    }
  );

  useEffect(() => {
    if (
      inferenceProgress &&
      inferenceProgress.finished + inferenceProgress.failed ===
        inferenceProgress.total
    ) {
      setIsInferenceInProgress(false);
    }
  }, [inferenceProgress]);

  const handleViewImageClick = () => {
    const {
      appliedForAllModelId,
      modelVisibilityObj,
      data: fileSets
    } = store.getState().review;

    if (url && !isSameFilteredLocation(url)) {
      dispatch(resetReviewData());
      dispatch(clearAllFilters());
      dispatch(setAppliedForAllModelId(modelId));
      navigate(url);
    } else if (appliedForAllModelId && appliedForAllModelId !== modelId) {
      if (!modelVisibilityObj[modelId]) {
        dispatch(
          setModelVisibilityObj({
            ...modelVisibilityObj,
            [modelId]: true
          })
        );
      }

      dispatch(setAppliedForAllModelId(modelId));
      dispatch(
        getDefect(
          fileSets.map((x: { fileSetId: number }) => x.fileSetId),
          modelId
        ) as any
      );
    }
  };

  const getInferencePercentage = useCallback(
    (value: InferenceProgressType | undefined): number => {
      if (value && value.total !== 0) {
        return Math.round(
          ((value.finished + value.failed) / value.total) * 100
        );
      }
      return 0;
    },
    [inferenceProgress]
  );

  const progressPercentage = getInferencePercentage(inferenceProgress);
  const isFailed =
    inferenceProgress && inferenceProgress.failed === inferenceProgress.total;
  const status = isInferenceInProgress
    ? 'pending'
    : isFailed
    ? 'failed'
    : 'success';

  // const currentTime = dayjs().format('D MMM YYYY | h:mm a');

  const folderNames = folders?.map(folder => folder.name).join(',');
  const title = `Model ${modelName} inferencing ${
    folderNames ? 'folder' : imageCount
  } ${folderNames || 'images'}`;

  return (
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <FontAwesomeIcon
          icon={STATUS_ICON[status].icon}
          className={clsx(styles.icon, STATUS_ICON[status].styles)}
        />
      </div>
      <div className={styles.detailsContainer}>
        <Typography className={styles.titleText}>{title}</Typography>
        <Show when={!!inferenceProgress}>
          <InferenceProgress
            total={inferenceProgress?.total || 0}
            success={inferenceProgress?.finished || 0}
            failed={inferenceProgress?.failed || 0}
          />
        </Show>

        <Show when={!!url && progressPercentage === 100}>
          <div style={{ display: 'flex' }}>
            <CommonButton
              text='View images'
              variant='tertiary'
              wrapperClass={styles.viewImageButton}
              onClick={handleViewImageClick}
            />
          </div>
        </Show>

        {/* <Typography className={styles.startTimeStamp}>{currentTime}</Typography> */}
      </div>
    </div>
  );
};
