export const ACTION_CONSTS = {
  TOGGLE_UPLOAD_MODAL: 'TOGGLE_UPLOAD_MODAL',
  SET_SELECTED_SESSION: 'SET_SELECTED_SESSION',
  INCREASE_FOLDERS_UPLOAD_COUNT: 'INCREASE_FOLDERS_UPLOAD_COUNT',
  DECREASE_FOLDERS_UPLOAD_COUNT: 'DECREASE_FOLDERS_UPLOAD_COUNT'
};

export const STATE_KEYS = {
  IS_MODAL_OPEN: 'isModalOpen',
  FOLDERS_UPLOAD_COUNT: 'foldersUploadCount',
  SELECTED_SESSION: 'selectedSession'
};
