export const ACTION_CONSTS = {
  TOGGLE_INFERENCE_MODAL: 'TOGGLE_INFERENCE_MODAL',
  SET_SELECTED_INFERENCE_SESSION: 'SET_SELECTED_INFERENCE_SESSION',
  IS_AI_DOWNLOAD_DRAWER: 'IS_AI_DOWNLOAD_DRAWER'
};

export const STATE_KEYS = {
  IS_INFERENCE_OPEN: 'isInferenceModalOpen',
  SELECTED_INFERENCE_SESSION: 'selectedInferenceSession',
  IS_AI_DOWNLOAD_DRAWER: 'IS_AI_DOWNLOAD_DRAWER'
};
