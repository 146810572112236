import { memo, useCallback, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';

import Show from 'app/hoc/Show';
import TruncateText from 'app/components/TruncateText';

import {
  faCircleCheck,
  faLoader,
  faRedoAlt,
  faTriangleExclamation
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import classes from './StatusCard.module.scss';
import clsx from 'clsx';
import CommonButton from 'app/components/ReviewButton';
import useFolderUploadStates from 'app/hooks/FloatingWidgetHooks/FolderUpload/useFolderUploadStates';

const STATUS_ICON = {
  success: {
    icon: faCircleCheck,
    styles: classes.successIcon
  },
  pending: {
    icon: faLoader,
    styles: classes.pendingIcon
  },
  failed: {
    icon: faTriangleExclamation,
    styles: classes.failedIcon
  }
};

export const StatusCard = memo(
  ({
    session,
    isRetry,
    isError,
    isComplete,
    onFileUploadStart,
    onFileRetry
  }) => {
    const { name, upload_task_id, new_files_count } = session;

    // const dispatch = useDispatch();

    const [state, customDispatch, { setFolderRetry }] =
      useFolderUploadStates(true);

    const isCurrentFolderUploading =
      state.uploadIds[state.uploadIds.length - 1] === upload_task_id;

    // const isCurrentFolderUploading = useSelector(
    //   selectIsFolderUploadingById(upload_task_id)
    // );

    const onRetry = useCallback(() => {
      customDispatch(setFolderRetry(upload_task_id));
    }, []);

    useEffect(() => {
      if (isCurrentFolderUploading) {
        if (isRetry) {
          return onFileRetry(session);
        } else {
          return onFileUploadStart(session);
        }
      }
    }, [isCurrentFolderUploading]);

    const status = isError
      ? 'failed'
      : isComplete
      ? 'success'
      : !isError && !isComplete
      ? 'pending'
      : null;

    const statusText = isError
      ? 'failed while uploading'
      : isComplete
      ? 'finished uploading'
      : !isError && !isComplete
      ? 'uploading'
      : null;

    return (
      <div className={classes.statusCard}>
        <div className={classes.iconContainer}>
          <FontAwesomeIcon
            icon={STATUS_ICON[status].icon}
            className={clsx(classes.icon, STATUS_ICON[status].styles)}
          />
        </div>
        <div className={classes.detailsContainer}>
          <span>
            {new_files_count} images {statusText} to folder {name}
          </span>

          <Show when={isError}>
            <div className={classes.error}>
              <Show when={isRetry}>
                <CommonButton
                  text='Retry'
                  variant='tertiary'
                  wrapperClass={classes.retryButton}
                  icon={<FontAwesomeIcon icon={faRedoAlt} />}
                  onClick={onRetry}
                />
              </Show>
            </div>
          </Show>
        </div>
      </div>
    );
  }
);
